<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="智能终端运行保障系统" describe="医院智能终端运行保障系统，通过AIoT物联网和AI、3D数字孪生，建立智慧医院可管可控一体化平台，提供从管理端-运营端-服务端-患者端全闭环一站式服务，全面打造医院智慧运行保障解决方案。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/support1.png" class="img1" alt="">
        <img src="@/assets/product/support2.png" class="img2" alt="">
        <img src="@/assets/product/support3.png" class="img3" alt="">
        <img src="@/assets/product/support4.png" class="img4" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1315px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .img1 {
      width: 1240px;
      height: 832px;
    }
    .img2 {
      width: 944px;
      height: 225px;
    }
    .img3 {
      width: 1240px;
      height: 650px;
      margin-bottom: 80px;
    }
    .img4 {
      width: 100%;
      height: 704px;
    }
  }
}
</style>
